import { IconButton, Tooltip } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';
import { useDialog } from 'context/dialogContext';
import { useToast } from 'context/toastContext';

const CopyAction = ({ title, id }: { title: string; id: string }) => {
  const { showSuccessToast } = useToast();
  const { showDialog, closeDialog } = useDialog();
  const handleClick = () => {
    showDialog({
      title: `Вы уверены, что хотите создать копию карты компетенций ${title}`,
      confirmText: 'Создать',
      onConfirm: () => {
        // TODO integrate copy map
        console.log('copy', id);
        showSuccessToast('Копия карты компетенций создана');
        closeDialog();
      },
    });
  };
  return (
    <Tooltip title='Копировать карту компетенций'>
      <IconButton onClick={handleClick}>
        <ContentCopy />
      </IconButton>
    </Tooltip>
  );
};

export default CopyAction;
