import React, { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers';
import { CalendarIcon } from 'assets/icons';
import { Dayjs } from 'dayjs';

type DateInputProps = {
  value: Dayjs | null;
  onChange: (date: Dayjs | null) => void;
  label?: string;
  error?: boolean;
  disabled?: boolean;
};

const DatePicker: FC<DateInputProps> = ({ value, onChange, label, error, disabled }) => (
  <Stack spacing={0.5}>
    {label && (
      <Typography variant='body14rg' sx={{ color: 'base.500' }}>
        {label}
      </Typography>
    )}
    <MuiDatePicker
      value={value}
      onChange={onChange}
      views={['day', 'month', 'year']}
      format='DD.MM.YYYY'
      disabled={disabled}
      slots={{
        openPickerIcon: CalendarIcon,
      }}
      slotProps={{
        textField: {
          fullWidth: true,
          error,
        },
        openPickerButton: {
          sx: { color: 'base.200' },
        },
        openPickerIcon: {
          sx: { width: 16, height: 16 },
        },
      }}
    />
  </Stack>
);

export default DatePicker;
