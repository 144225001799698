import TableLayout from 'layout/TableLayout';
import { User } from 'constants/user';
import USER_LIST_COLUMNS from 'pages/User/List/columns';
import USER_LIST_FILTERS from 'pages/User/List/filters';
import { useNavigate } from 'react-router-dom';

const UserListPage = () => {
  const navigate = useNavigate();
  const loading = false;
  const totalCount = 10;
  const data: User[] = [
    {
      id: '550e8400-e29b-41d4-a716-446655440000',
      createdAt: '2023-01-15',
      fullName: 'Иван Иванов',
      email: 'ivan.ivanov@example.com',
      isBlocked: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440001',
      createdAt: '2022-12-10',
      fullName: 'Мария Петрова',
      email: 'maria.petrova@example.com',
      isBlocked: true,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440002',
      createdAt: '2023-05-21',
      fullName: 'Сергей Сергеев',
      email: 'sergey.sergeev@example.com',
      isBlocked: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440003',
      createdAt: '2021-07-30',
      fullName: 'Анна Смирнова',
      email: 'anna.smirnova@example.com',
      isBlocked: true,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440004',
      createdAt: '2022-11-05',
      fullName: 'Дмитрий Кузнецов',
      email: 'dmitry.kuznetsov@example.com',
      isBlocked: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440005',
      createdAt: '2023-02-14',
      fullName: 'Ольга Соколова',
      email: 'olga.sokolova@example.com',
      isBlocked: true,
    },
  ];

  return (
    <TableLayout<User>
      onRowClick={(user) => navigate(user.id)}
      filters={USER_LIST_FILTERS}
      columns={USER_LIST_COLUMNS}
      data={data}
      loading={loading}
      emptyPlaceholderText='Пока нет пользователей'
      totalCount={totalCount}
      rowsPerPageText='Пользователей на странице'
      refetch={() => {}} // TODO integrate refetch
    />
  );
};

export default UserListPage;
