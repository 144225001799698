import React from 'react';
import { Box } from '@mui/material';
import { styled, CSSObject } from '@mui/system';
import Header from 'layout/MainLayout/components/Header';
import MenuDrawer from 'layout/MainLayout/components/MenuDrawer';
import MainContent from 'layout/MainLayout/components/MainContent';

const Root = styled(Box)(
  (): CSSObject => ({
    display: 'flex',
  }),
);

const MainLayout: React.FC = () => {
  return (
    <Root>
      <Header />
      <MenuDrawer />
      <MainContent />
    </Root>
  );
};

export default MainLayout;
