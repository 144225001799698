import { TableColumn } from 'layout/TableLayout/types.d';
import dayjs from 'dayjs';
import { Status } from 'components/common';
import { ASSESSMENT_STATUS_COLOR, Testing } from 'constants/assessment';
import DownloadReportsAction from './components/DownloadReportsAction';

const ASSESSMENTS_LIST_COLUMNS: TableColumn<Testing>[] = [
  {
    title: 'Дата создания',
    name: 'createdAt',
    render: ({ createdAt }) => dayjs(createdAt).format('DD.MM.YYYY'),
  },
  {
    title: 'Карта компетенций',
    name: 'competencyMap',
    render: ({ competencyMap }) => competencyMap.title,
  },
  {
    title: 'ФИО кандидата',
    name: 'candidate.fullName',
    render: ({ candidate }) => candidate.fullName,
  },
  {
    title: 'Ник кандидата',
    name: 'candidate.nickname',
    render: ({ candidate }) => candidate.nickname,
  },
  {
    title: 'Статус',
    name: 'status',
    render: ({ status }) => {
      return <Status status={status} statusDetails={ASSESSMENT_STATUS_COLOR} />;
    },
  },
  {
    title: 'Отчет',
    name: 'reports',
    render: ({ reports }) => <DownloadReportsAction {...{ reports }} />,
  },
];

export default ASSESSMENTS_LIST_COLUMNS;
