import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';
import { BOOLEAN_OPTIONS } from 'constants/global';

const USER_COMPANY_LIST_FILTERS: FilterField[] = [
  {
    type: FilterInputType.DATE,
    title: 'Дата добавления',
    name: 'addedAt',
  },
  {
    type: FilterInputType.TEXT,
    title: 'ИНН',
    name: 'inn',
  },
  {
    type: FilterInputType.TEXT,
    title: 'Наименование компании',
    name: 'companyName',
  },
  {
    type: FilterInputType.TEXT,
    title: 'Должность',
    name: 'position',
  },
  {
    type: FilterInputType.AUTOCOMPLETE,
    title: 'Создатель',
    name: 'isCreator',
    options: BOOLEAN_OPTIONS,
  },
  {
    type: FilterInputType.AUTOCOMPLETE,
    title: 'Руководитель',
    name: 'isDirector',
    options: BOOLEAN_OPTIONS,
  },
];

export default USER_COMPANY_LIST_FILTERS;
