import { createTheme } from '@mui/material';
import { ruRU } from '@mui/x-date-pickers/locales';
import components from './components';
import palette from './palette';
import typography from './typography';

const createAppTheme = () =>
  createTheme(
    {
      components,
      palette,
      typography,
    },
    ruRU,
  );

export default createAppTheme;
