import React, { FC } from 'react';
import { Box, CircularProgress, SxProps } from '@mui/material';

type CircularLoadingProps = {
  sx?: SxProps;
  size?: number;
};

const CircularLoading: FC<CircularLoadingProps> = ({ sx, size = 40 }) => (
  <Box
    sx={{
      ...sx,
      display: 'flex',
      justifyContent: 'center',
      height: '100%',
      alignItems: 'center',
      py: 2,
    }}>
    <CircularProgress size={size} />
  </Box>
);

export default CircularLoading;

CircularLoading.defaultProps = {
  sx: {},
  size: 40,
};
