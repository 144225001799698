import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';
import { BOOLEAN_OPTIONS } from 'constants/global';

const USER_LIST_FILTERS: FilterField[] = [
  {
    title: 'Дата создания',
    type: FilterInputType.DATE,
    name: 'createdAt',
  },
  {
    title: 'ФИО',
    type: FilterInputType.TEXT,
    name: 'fullName',
  },
  {
    title: 'Email',
    type: FilterInputType.TEXT,
    name: 'email',
  },
  {
    title: 'Заблокирован',
    type: FilterInputType.AUTOCOMPLETE,
    name: 'isBlocked',
    options: BOOLEAN_OPTIONS,
  },
];

export default USER_LIST_FILTERS;
