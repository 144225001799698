import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';
import { COMPANY_STATUS_OPTIONS } from 'constants/company';

const COMPANY_FILTERS: FilterField[] = [
  {
    name: 'createdAt',
    type: FilterInputType.DATE,
    title: 'Дата создания',
  },
  {
    name: 'inn',
    type: FilterInputType.TEXT,
    title: 'ИНН',
  },
  {
    name: 'shortName',
    type: FilterInputType.TEXT,
    title: 'Краткое наименование',
  },
  {
    name: 'companyCreator',
    type: FilterInputType.TEXT,
    title: 'Создатель компании',
  },
  {
    name: 'status',
    type: FilterInputType.AUTOCOMPLETE,
    title: 'Статус',
    options: COMPANY_STATUS_OPTIONS,
  },
];

export default COMPANY_FILTERS;
