import { TableColumn } from 'layout/TableLayout/types';
import dayjs from 'dayjs';
import { COMPANY_STATUS, CompanyStatus } from 'constants/company';
import Status from 'components/common/Status';
import { CompanyData } from './index';

const COMPANY_TABLE: TableColumn<CompanyData>[] = [
  {
    title: 'Дата создания',
    render: (data) => dayjs(data.createdAt).format('DD.MM.YYYY'),
    name: 'createdAt',
  },
  {
    title: 'ИНН',
    render: (data) => data.inn,
    name: 'inn',
  },
  {
    title: 'Краткое наименование',
    render: (data) => data.shortName,
    name: 'shortName',
  },
  {
    title: 'Создатель компании',
    render: (data) => data.companyCreator,
    name: 'companyCreator',
  },
  {
    title: 'Статус',
    render: (data) => {
      return <Status<CompanyStatus> status={data.status} statusDetails={COMPANY_STATUS} />;
    },
    name: 'status',
  },
];

export default COMPANY_TABLE;
