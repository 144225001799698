import { IconButton, Stack, Tooltip } from '@mui/material';
import { Download } from '@mui/icons-material';
import { Report } from 'constants/assessment';

const DownloadReportsAction = ({ reports }: { reports?: Report[] }) => {
  return (
    <Stack direction='row' spacing={2}>
      {reports?.map((report: Report) => {
        const { type } = report;
        const tooltipText =
          type === 'extendedReport' ? 'Скачать расширенный отчет' : 'Скачать отчет';
        return (
          <Tooltip title={tooltipText} key={report.id}>
            <IconButton>
              <Download />
            </IconButton>
          </Tooltip>
        );
      })}
    </Stack>
  );
};

export default DownloadReportsAction;
