import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';
import { GRADE_OPTIONS } from 'constants/global';

const COMPETENCY_MAP_FILTERS: FilterField[] = [
  {
    type: FilterInputType.DATE,
    name: 'createdAt',
    title: 'Дата создания',
  },
  {
    type: FilterInputType.TEXT,
    name: 'title',
    title: 'Название',
  },
  {
    type: FilterInputType.AUTOCOMPLETE,
    name: 'grade',
    options: GRADE_OPTIONS,
    title: 'Грейд',
  },
];

export default COMPETENCY_MAP_FILTERS;
