import React, { createContext, useContext, useState, ReactNode, FC, useMemo } from 'react';
import { Dialog, DialogProps } from 'components/modals';

interface DialogContextProps {
  showDialog: (props: Omit<DialogProps, 'open' | 'onClose'>) => void;
  closeDialog: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

const DialogProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [dialogProps, setDialogProps] = useState<Omit<DialogProps, 'open' | 'onClose'> | null>(
    null,
  );

  const showDialog = (props: Omit<DialogProps, 'open' | 'onClose'>) => {
    setDialogProps(props);
  };

  const closeDialog = () => {
    setDialogProps(null);
  };

  const contextValue = useMemo(() => ({ showDialog, closeDialog }), []);

  return (
    <DialogContext.Provider value={contextValue}>
      {children}
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      {dialogProps && <Dialog {...dialogProps} open={Boolean(dialogProps)} onClose={closeDialog} />}
    </DialogContext.Provider>
  );
};

const useDialog = (): DialogContextProps => {
  const context = useContext(DialogContext);
  if (context === undefined) {
    throw new Error('useDialog must be used within a DialogProvider');
  }
  return context;
};

export { DialogProvider, useDialog };
