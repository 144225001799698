import React from 'react';
import { AppBar, Box, Theme, Toolbar } from '@mui/material';
import { styled } from '@mui/system';
import LogoImg from 'assets/images/logo.svg';
import User from 'layout/MainLayout/components/User';

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  zIndex: (theme as Theme).zIndex.drawer + 1,
  boxShadow: 'none',
}));

const Header: React.FC = () => (
  <StyledAppBar position='fixed'>
    <Toolbar sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box
        src={LogoImg}
        component='img'
        alt='FoxtailBox Logo'
        sx={{ height: 40, width: 172, mr: 4 }}
      />
      <User />
    </Toolbar>
  </StyledAppBar>
);

export default Header;
