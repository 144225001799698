import React, { FormHTMLAttributes, ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn } from 'react-hook-form';

export type FormProps<T extends FieldValues> = {
  form: UseFormReturn<T>;
  children: ReactNode;
};

/*
eslint-disable react/jsx-props-no-spreading
*/

const Form = <T extends FieldValues>(props: FormHTMLAttributes<HTMLFormElement> & FormProps<T>) => {
  const { form, children, onSubmit, ...rest } = props;
  return (
    <FormProvider {...form}>
      <form
        noValidate
        onSubmit={(e) => {
          if (typeof onSubmit === 'function') onSubmit(e);
          e.preventDefault();
        }}
        {...rest}>
        {children}
      </form>
    </FormProvider>
  );
};

export default Form;
