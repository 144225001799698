import { createBrowserRouter } from 'react-router-dom';
import MainLayout from 'layout/MainLayout/MainLayout';
import ROUTES from 'constants/routes';
import CompanyListPage from 'pages/Company/List';
import CompanyViewPage from 'pages/Company/View';
import UserListPage from 'pages/User/List';
import UserViewPage from 'pages/User/View';

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children: [
      {
        path: ROUTES.COMPANIES,
        children: [
          {
            index: true,
            element: <CompanyListPage />,
          },
          {
            path: ':companyId',
            element: <CompanyViewPage />,
          },
        ],
      },
      {
        path: ROUTES.USERS,
        children: [
          {
            index: true,
            element: <UserListPage />,
          },
          {
            path: ':userId',
            element: <UserViewPage />,
          },
        ],
      },
    ],
  },
]);

export default router;
