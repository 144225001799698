import React from 'react';
import { useForm } from 'react-hook-form';
import { Button, Grid, Stack } from '@mui/material';
import { AutocompleteControl, DatePickerControl, TextFieldControl } from 'components/inputs';
import Form from 'components/form/Form';
import { FiltersProps, FilterInputType } from 'layout/TableLayout/types.d';

import useFilter from 'layout/TableLayout/components/useFilter';

const Filters: React.FC<FiltersProps> = ({ filters }) => {
  const form = useForm({});

  const { handleSubmit } = form;

  const { handleReset, handleFiltersSubmit } = useFilter({ form, filters });

  return (
    <Form form={form} onSubmit={handleSubmit(handleFiltersSubmit)}>
      <Grid container spacing={2}>
        {filters.map((filter) => (
          <Grid item key={filter.name} xs={12} md={3}>
            {filter.type === FilterInputType.TEXT && (
              <TextFieldControl name={filter.name} label={filter.title} />
            )}
            {filter.type === FilterInputType.AUTOCOMPLETE && filter.options && (
              <AutocompleteControl
                name={filter.name}
                label={filter.title}
                options={filter.options}
              />
            )}
            {filter.type === FilterInputType.DATE && (
              <DatePickerControl name={filter.name} label={filter.title} />
            )}
          </Grid>
        ))}
        <Grid item xs={12} sx={{ justifyContent: 'flex-end', display: 'flex' }}>
          <Stack spacing={2} direction='row'>
            <Button type='submit' variant='contained' color='secondary' onClick={handleReset}>
              Сбросить
            </Button>
            <Button type='submit' variant='contained' color='primary'>
              Применить
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export default Filters;
