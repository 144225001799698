import React, { FC } from 'react';
import { useController, UseControllerProps } from 'react-hook-form';
import dayjs from 'dayjs';
import DatePicker from './DatePicker';

type ISO8601String = string & { __brand: 'ISO8601String' };

const isValidDateString = (value: string): boolean => {
  return dayjs(value).isValid();
};

type DatePickerControlProps = {
  name: string;
  label?: string;
  rules?: UseControllerProps['rules'];
  defaultValue?: ISO8601String;
  disabled?: boolean;
};

const DatePickerControl: FC<DatePickerControlProps> = ({
  name,
  label,
  rules,
  defaultValue,
  disabled,
}) => {
  const {
    field,
    fieldState: { error },
  } = useController({ name, rules, defaultValue });

  return (
    <DatePicker
      value={field.value && isValidDateString(field.value) ? dayjs(field.value) : null}
      onChange={field.onChange}
      label={label}
      error={!!error}
      disabled={disabled}
    />
  );
};

export default DatePickerControl;
