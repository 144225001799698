import { SelectChangeEvent, Stack } from '@mui/material';
import { COMPANY_STATUS_OPTIONS, COMPANY_STATUS_TITLE, CompanyStatus } from 'constants/company';
import { PageTitle } from 'components/common';
import { Select } from 'components/inputs';
import { useDialog } from 'context/dialogContext';
import SecretCompanyCode from './SecretCompanyCode';

interface Company {
  shortName: string;
  companyCode: string;
  status: CompanyStatus;
}

interface SelectItem {
  id: CompanyStatus;
  displayName: string;
}

const Header = () => {
  const { showDialog } = useDialog();
  // TODO integrate query
  const data: Company = {
    shortName: 'Tech Innovations LLC',
    companyCode: 'TI-2024-001',
    status: CompanyStatus.Confirmed,
  };
  const availableStatuses = COMPANY_STATUS_OPTIONS;

  const handleStatusChange = (event: SelectChangeEvent<CompanyStatus>) => {
    const newStatus = event.target.value as CompanyStatus;
    showDialog({
      confirmText: 'Изменить',
      onConfirm: () => console.log('confirm', newStatus), // TODO integrate mutation
      title: `Вы уверены, что хотите изменить статус компании на ${COMPANY_STATUS_TITLE[newStatus]}?`,
    });
  };

  return (
    <Stack justifyContent='space-between' direction={{ xs: 'column', md: 'row' }}>
      <PageTitle title={`Компания ${data.shortName}`} />
      <Stack direction='row' spacing={2}>
        <SecretCompanyCode code={data.companyCode} />
        <Select<SelectItem, 'id'>
          value={data.status}
          labelName='displayName'
          valueName='id'
          items={availableStatuses}
          onChange={handleStatusChange}
        />
      </Stack>
    </Stack>
  );
};

export default Header;
