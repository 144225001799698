import React from 'react';
import { Stack, Typography } from '@mui/material';
import MuiAutocomplete from '@mui/material/Autocomplete';
import { KeyboardArrowDown } from '@mui/icons-material';

import useAutocompleteUtils from 'components/inputs/Autocomplete/useAutocompleteUtils';
import { AutocompleteOption, AutocompleteProps, Value } from './types.d';

/* eslint-disable react/jsx-props-no-spreading */

const Autocomplete = <TOption extends AutocompleteOption>(props: AutocompleteProps<TOption>) => {
  const {
    optionName = 'displayName',
    options,
    multiple,
    disabled,
    onInputChange,
    placeholder,
    loading,
    getOptionLabel,
    freeSolo,
    label,
    value,
    error,
    onChange,
    onChangeTextField,
    filterOptionsParams,
    handleOnChange,
    ...otherProps
  } = props;

  const { handleChange, handleFilterOptions, renderOption, renderInput, isOptionEqualToValue } =
    useAutocompleteUtils<TOption>(props);
  return (
    <Stack spacing={0.5}>
      {label && (
        <Typography variant='body14rg' sx={{ color: 'base.500' }}>
          {label}
        </Typography>
      )}
      <MuiAutocomplete
        {...otherProps}
        size='small'
        value={multiple ? (value as Value[]) || [] : (value as Value) || null}
        multiple={multiple}
        options={options || []}
        filterOptions={handleFilterOptions}
        disabled={disabled}
        loading={loading}
        freeSolo={freeSolo}
        loadingText='Загрузка...'
        noOptionsText='Нет данных'
        getOptionLabel={(option) => {
          if (getOptionLabel) return getOptionLabel(option);
          return typeof option === 'string' ? option : (option[optionName] as string);
        }}
        renderInput={renderInput}
        popupIcon={<KeyboardArrowDown sx={{ color: 'base.200', width: 24, height: 24 }} />}
        renderOption={renderOption}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={handleChange}
        onInputChange={onInputChange}
      />
    </Stack>
  );
};

export default Autocomplete;
