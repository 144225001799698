import { Typography, TypographyProps } from '@mui/material';
import { useEffect } from 'react';

const PageTitle = ({ title, ...typographyProps }: TypographyProps & { title: string }) => {
  useEffect(() => {
    document.title = title;
  }, []);

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Typography {...typographyProps} variant='h28'>
      {title}
    </Typography>
  );
};

export default PageTitle;
