import Avatar from '@mui/material/Avatar';
import { createUserName, getInitials, removeAuthCookies } from 'utils/index';
import { IconButton, Stack, Typography } from '@mui/material';
import React from 'react';
import { LogoutIcon } from 'assets/icons';

const User = () => {
  // TODO integrate
  const me = {
    firstName: 'John',
    lastName: 'Doe',
    middleName: 'Michael',
  };

  const userName = createUserName(me);

  const logout = () => {
    removeAuthCookies(['accessToken', 'refreshToken']);
  };

  return (
    <Stack alignItems='center' direction='row'>
      <Avatar sx={{ width: 33, height: 33, mr: 0.8 }}>{getInitials(userName)}</Avatar>
      <Typography variant='body16rg'>{userName}</Typography>
      <IconButton onClick={logout}>
        <LogoutIcon sx={{ color: 'primary.main' }} />
      </IconButton>
    </Stack>
  );
};

export default User;
