import React from 'react';
import { Box } from '@mui/material';
import { styled, CSSObject } from '@mui/system';
import { Outlet } from 'react-router-dom';

const Content = styled(Box)(
  ({ theme }): CSSObject => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    marginTop: theme.spacing(8),
    height: `calc(100vh - ${theme.spacing(8)}px)`,
    overflow: 'auto',
  }),
);

const MainContent: React.FC = () => (
  <Content component='main'>
    <Outlet />
  </Content>
);

export default MainContent;
