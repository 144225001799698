import TableLayout from 'layout/TableLayout';
import { Grade } from 'constants/global';
import COMPETENCY_MAP_COLUMNS, { CompetencyMap } from './columns';
import COMPETENCY_MAP_FILTERS from './filters';

const CompetencyMapList = () => {
  const loading = false;
  const totalCount = 10;
  const data: CompetencyMap[] = [
    {
      id: '550e8400-e29b-41d4-a716-446655440000',
      createdAt: '2023-01-01',
      isTemplate: true,
      title: 'Project Management',
      specialization: 'Management',
      grade: Grade.Senior,
      knowledgeBlocks: 5,
      testCount: 3,
      isDeleted: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440001',
      createdAt: '2023-02-15',
      isTemplate: false,
      title: 'Software Development',
      specialization: 'Engineering',
      grade: Grade.Middle,
      knowledgeBlocks: 8,
      testCount: 6,
      isDeleted: true,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440002',
      createdAt: '2023-03-10',
      isTemplate: true,
      title: 'Data Analysis',
      specialization: 'Data Science',
      grade: Grade.Junior,
      knowledgeBlocks: 4,
      testCount: 2,
      isDeleted: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440003',
      createdAt: '2023-04-05',
      isTemplate: false,
      title: 'Marketing Strategy',
      specialization: 'Marketing',
      grade: Grade.Senior,
      knowledgeBlocks: 7,
      testCount: 5,
      isDeleted: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440004',
      createdAt: '2023-05-20',
      isTemplate: true,
      title: 'Financial Analysis',
      specialization: 'Finance',
      grade: Grade.Middle,
      knowledgeBlocks: 6,
      testCount: 4,
      isDeleted: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440005',
      createdAt: '2023-06-15',
      isTemplate: false,
      title: 'Customer Service',
      specialization: 'Support',
      grade: Grade.Junior,
      knowledgeBlocks: 3,
      testCount: 1,
      isDeleted: false,
    },
  ];

  return (
    <TableLayout<CompetencyMap>
      filters={COMPETENCY_MAP_FILTERS}
      columns={COMPETENCY_MAP_COLUMNS}
      data={data}
      loading={loading}
      emptyPlaceholderText='Пока нет карт компетенций'
      totalCount={totalCount}
      rowsPerPageText='Карт компетенций на странице'
      refetch={() => {}}
    />
  );
};

export default CompetencyMapList;
