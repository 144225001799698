import ROUTES from 'constants/routes';
import BusinessIcon from '@mui/icons-material/Business';
import PeopleIcon from '@mui/icons-material/People';

const MENU_ROUTES = [
  {
    title: 'Компании',
    link: ROUTES.COMPANIES,
    icon: <BusinessIcon />,
  },
  {
    title: 'Пользователи',
    link: ROUTES.USERS,
    icon: <PeopleIcon />,
  },
];

export default MENU_ROUTES;
