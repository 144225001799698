import { FilterField, FilterInputType } from 'layout/TableLayout/types.d';

const EMPLOYEE_LIST_FILTERS: FilterField[] = [
  {
    type: FilterInputType.TEXT,
    name: 'fullName',
    title: 'ФИО',
  },
  {
    type: FilterInputType.TEXT,
    name: 'email',
    title: 'Email',
  },
];

export default EMPLOYEE_LIST_FILTERS;
