import React, { useEffect } from 'react';
import CircularLoading from 'components/common/CircularLoading';
import EmptyList from 'components/common/EmptyList';
import Filters from 'layout/TableLayout/components/Filters';
import Table from 'layout/TableLayout/components/Table';
import { useLocation } from 'react-router-dom';
import qs from 'qs';
import { DEFAULT_LIMIT } from 'layout/TableLayout/components/TablePagination';
import { Stack } from '@mui/material';
import { Identifiable, TableLayoutProps } from 'layout/TableLayout/types';

const TableLayout = <Rows extends Identifiable>({
  filters = [],
  error,
  loading,
  emptyPlaceholderText,
  onRowClick,
  columns,
  data,
  totalCount,
  rowsPerPageText,
  refetch,
}: TableLayoutProps<Rows>) => {
  const location = useLocation();

  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  useEffect(() => {
    refetch({
      page: Number(query.page) || 0,
      limit: Number(query.limit) || DEFAULT_LIMIT,
    });
  }, [query, refetch]);

  if (loading) return <CircularLoading />;

  return (
    <Stack spacing={2.5}>
      {filters.length > 0 && <Filters filters={filters} />}
      {error || !data || !data.length ? (
        <EmptyList emptyPlaceholderText={emptyPlaceholderText} />
      ) : (
        <Table<Rows> {...{ onRowClick, columns, data, totalCount, rowsPerPageText }} />
      )}
    </Stack>
  );
};

export default TableLayout;
