import { TableColumn } from 'layout/TableLayout/types.d';
import dayjs from 'dayjs';
import { Star, StarOutline } from '@mui/icons-material';
import { orange } from '@mui/material/colors';
import { Status } from 'components/common';
import { Grade, GRADE_COLORS } from 'constants/global';
import CopyAction from 'pages/Company/View/tabs/CompetencyMapList/components/CopyAction';
import DeleteAction from './components/DeleteAction';

export interface CompetencyMap {
  id: string;
  createdAt: string;
  isTemplate: boolean;
  title: string;
  specialization: string;
  grade: Grade;
  knowledgeBlocks: number;
  testCount: number;
  isDeleted: boolean;
}

const COMPETENCY_MAP_COLUMNS: TableColumn<CompetencyMap>[] = [
  {
    title: 'Дата создания',
    name: 'createdAt',
    render: (map) => dayjs(map.createdAt).format('DD.MM.YYYY'),
  },
  {
    title: 'Шаблон',
    name: 'isTemplate',
    render: ({ isTemplate }) => {
      return isTemplate ? (
        <StarOutline sx={{ color: orange[400] }} />
      ) : (
        <Star sx={{ color: orange[400] }} />
      );
    },
  },
  {
    title: 'Название',
    name: 'title',
    render: (map) => map.title,
  },
  {
    title: 'Специализация',
    name: 'specialization',
    render: (map) => map.specialization,
  },
  {
    title: 'Грейд',
    name: 'grade',
    render: ({ grade }) => {
      return <Status status={grade} statusDetails={GRADE_COLORS} />;
    },
  },
  {
    title: 'Количество блоков знаний',
    name: 'knowledgeBlocks',
    render: ({ knowledgeBlocks }) => knowledgeBlocks,
  },
  {
    title: 'Количество тестирований',
    name: 'testCount',
    render: ({ testCount }) => testCount,
  },
  {
    title: 'Удаление',
    name: 'isDeleted',
    render: ({ isDeleted, id, title }) => <DeleteAction {...{ isDeleted, id, title }} />,
  },
  {
    title: 'Действия',
    name: 'actions',
    render: ({ id, title }) => <CopyAction {...{ id, title }} />,
  },
];

export default COMPETENCY_MAP_COLUMNS;
