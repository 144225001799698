import React from 'react';
import { ApolloError } from '@apollo/client';
import { AutocompleteOption } from 'components/inputs/Autocomplete/types.d';

export enum FilterInputType {
  DATE = 'DATE',
  TEXT = 'TEXT',
  AUTOCOMPLETE = 'AUTOCOMPLETE',
}

export interface FilterField {
  type: FilterInputType;
  title: string;
  name: string;
  options?: AutocompleteOption[];
}

export interface FiltersProps {
  filters: FilterField[];
}

export const INPUT_DEFAULT_VALUE: { [key in FilterInputType]: null | '' } = {
  [FilterInputType.DATE]: null,
  [FilterInputType.TEXT]: '',
  [FilterInputType.AUTOCOMPLETE]: null,
};

export interface Identifiable {
  id: string | number;
}

export interface TableColumn<T> {
  title: string;
  render: (data: T) => React.ReactNode;
  sortable?: boolean;
  name: keyof T | string;
}

export interface TableQueryVariables {
  page: number;
  limit: number;
  filters?: Record<string, string>;
  sortField?: string;
  sortOrder?: 'asc' | 'desc';
}

export interface TableLayoutProps<T> {
  filters?: FilterField[];
  columns: TableColumn<T>[];
  data: T[];
  loading: boolean;
  error?: ApolloError;
  emptyPlaceholderText: string;
  onRowClick?: (row: T) => void;
  totalCount: number;
  rowsPerPageText: string;
  refetch: (variables: TableQueryVariables) => void;
  // refetch: (variables?: TableQueryVariables) => Promise<ApolloQueryResult<T>>; // Тип refetch функции
}

export interface TableProps<T> {
  columns: TableColumn<T>[];
  data: T[];
  onRowClick?: (row: T) => void;
  totalCount: number;
  rowsPerPageText: string;
}

export interface TablePaginationProps {
  totalCount: number;
  rowsPerPageText: string;
}
