import { AutocompleteControl, DatePickerControl, TextFieldControl } from 'components/inputs';
import Form from 'components/form/Form';
import { Grid, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import {
  COMPANY_STATUS_OPTIONS,
  COMPANY_TYPE_OPTIONS,
  CompanyStatus,
  CompanyType,
} from 'constants/company';
import { CircularLoading } from 'components/common';
import dayjs from 'dayjs';
import { LoadingButton } from '@mui/lab';
import { useToast } from 'context/toastContext';

interface CompanyFormData {
  inn: string;
  fullCompanyName: string;
  shortCompanyName: string;
  kpp: string;
  ogrn: string;
  ogrnDate: Date | null;
  companyType: CompanyType;
  directorName: string;
  directorPosition: string;
  okro: string;
  okato: string;
  oktmo: string;
  address: string;
  registrationDate: Date | null;
  status: CompanyStatus;
  position: string;
}

const CompanyData = () => {
  const { showErrorToast, showSuccessToast } = useToast();

  const loading = false;
  const data = {
    inn: '7701234567',
    fullCompanyName: 'ООО "Примерная Компания"',
    shortCompanyName: 'Пример',
    kpp: '770101001',
    ogrn: '1027700132195',
    ogrnDate: '2022-03-15',
    companyType: CompanyType.LEGAL_ENTITY,
    directorName: 'Иванов Иван Иванович',
    directorPosition: 'Генеральный директор',
    okro: '12345678',
    okato: '45286560000',
    oktmo: '45384000',
    address: 'г. Москва, ул. Примерная, д. 1',
    registrationDate: '2020-01-10',
    status: CompanyStatus.Confirmed,
    position: 'Менеджер',
  };

  const form = useForm<CompanyFormData>({
    values: {
      ...data,
      ogrnDate: dayjs(data.ogrnDate).toDate(),
      registrationDate: dayjs(data.registrationDate).toDate(),
    },
  });

  const { handleSubmit } = form;

  const onSubmit = (formData: CompanyFormData) => {
    console.log(formData);
    showSuccessToast('Данные сохранены');
  };

  const onError = () => showErrorToast('Проверьте правильность заполнения формы');

  if (loading) return <CircularLoading />;

  return (
    <Form form={form}>
      <Grid container columnSpacing={3.5} rowSpacing={3.5}>
        <Grid item xs={12} md={6}>
          <Stack spacing={3.5}>
            <TextFieldControl label='ИНН' name='inn' rules={{ required: true }} />
            <TextFieldControl
              label='Полное наименование'
              name='fullCompanyName'
              rules={{ required: true }}
            />
            <TextFieldControl
              label='Краткое наименование'
              name='shortCompanyName'
              rules={{ required: true }}
            />
            <TextFieldControl label='КПП' name='kpp' rules={{ required: true }} />
            <TextFieldControl label='ОГРН' name='ogrn' rules={{ required: true }} />
            <DatePickerControl
              name='ogrnDate'
              label='Дата выдачи ОГРН'
              rules={{ required: true }}
            />
            <AutocompleteControl
              name='companyType'
              label='Тип организации'
              options={COMPANY_TYPE_OPTIONS}
              rules={{ required: true }}
            />
            <TextFieldControl
              label='ФИО руководителя'
              name='directorName'
              rules={{ required: true }}
            />
            <TextFieldControl
              label='Должность руководителя'
              name='directorPosition'
              rules={{ required: true }}
            />
            <LoadingButton
              variant='contained'
              size='large'
              onClick={handleSubmit(onSubmit, onError)}>
              Сохранить
            </LoadingButton>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={3.5}>
            <TextFieldControl label='ОКРО' name='okro' rules={{ required: true }} />
            <TextFieldControl label='ОКАТО' name='okato' rules={{ required: true }} />
            <TextFieldControl label='ОКТМО' name='oktmo' rules={{ required: true }} />
            <TextFieldControl label='Адрес организации' name='address' rules={{ required: true }} />
            <DatePickerControl
              name='registrationDate'
              label='Дата регистрации'
              rules={{ required: true }}
            />
            <AutocompleteControl
              options={COMPANY_STATUS_OPTIONS}
              label='Статус организации'
              name='status'
              rules={{ required: true }}
            />
            <TextFieldControl
              label='Ваша должность в организации'
              name='position'
              rules={{ required: true }}
            />
          </Stack>
        </Grid>
      </Grid>
    </Form>
  );
};

export default CompanyData;
