import { generateOptions } from 'utils/format';
import { StatusDetails } from 'components/common/Status';
import palette from 'theme/palette';

export enum Grade {
  Junior = 'Junior',
  Middle = 'Middle',
  Senior = 'Senior',
}

export const GRADE_TITLE = {
  [Grade.Junior]: 'Junior',
  [Grade.Middle]: 'Middle',
  [Grade.Senior]: 'Senior',
};

export const GRADE_OPTIONS: Array<{ id: Grade; displayName: string }> =
  generateOptions(GRADE_TITLE);

export const GRADE_COLORS: StatusDetails<Grade> = {
  [Grade.Junior]: {
    title: GRADE_TITLE[Grade.Junior],
    color: palette.green.light,
    backgroundColor: palette.green.contrast,
  },
  [Grade.Middle]: {
    title: GRADE_TITLE[Grade.Middle],
    color: palette.orange.light,
    backgroundColor: palette.orange.contrast,
  },
  [Grade.Senior]: {
    title: GRADE_TITLE[Grade.Senior],
    color: palette.red.light,
    backgroundColor: palette.red.contrast,
  },
};

export const BOOLEAN_LABELS = {
  true: 'Да',
  false: 'Нет',
};

export const BOOLEAN_OPTIONS = generateOptions(BOOLEAN_LABELS);
