import { UserCompany } from 'constants/user';
import TableLayout from 'layout/TableLayout';
import USER_COMPANY_LIST_COLUMNS from './columns';
import USER_COMPANY_LIST_FILTERS from './filters';

const UserCompanyList = () => {
  // TODO integrate company user query
  const loading = false;
  const totalCount = 5;
  const data: UserCompany[] = [
    {
      id: '550e8400-e29b-41d4-a716-446655440010',
      companyId: '550e8400-e29b-41d4-a716-446655440020',
      addedAt: '2023-01-15',
      inn: '7701234567',
      companyName: "ООО 'Ромашка'",
      position: 'Генеральный директор',
      isCreator: true,
      isDirector: true,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440011',
      companyId: '550e8400-e29b-41d4-a716-446655440021',
      addedAt: '2022-12-10',
      inn: '7707654321',
      companyName: "АО 'Лютик'",
      position: 'Главный бухгалтер',
      isCreator: false,
      isDirector: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440012',
      companyId: '550e8400-e29b-41d4-a716-446655440022',
      addedAt: '2023-05-21',
      inn: '7701237654',
      companyName: "ЗАО 'Василек'",
      position: 'Менеджер',
      isCreator: false,
      isDirector: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440013',
      companyId: '550e8400-e29b-41d4-a716-446655440023',
      addedAt: '2021-07-30',
      inn: '7709876543',
      companyName: "ИП 'Петров'",
      position: 'Исполнительный директор',
      isCreator: false,
      isDirector: true,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440014',
      companyId: '550e8400-e29b-41d4-a716-446655440024',
      addedAt: '2022-11-05',
      inn: '7705678901',
      companyName: "ООО 'Одуванчик'",
      position: 'Руководитель отдела',
      isCreator: true,
      isDirector: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440015',
      companyId: '550e8400-e29b-41d4-a716-446655440025',
      addedAt: '2023-02-14',
      inn: '7706789012',
      companyName: "ООО 'Роза'",
      position: 'Заместитель директора',
      isCreator: false,
      isDirector: true,
    },
  ];

  return (
    <TableLayout<UserCompany>
      filters={USER_COMPANY_LIST_FILTERS}
      columns={USER_COMPANY_LIST_COLUMNS}
      data={data}
      loading={loading}
      emptyPlaceholderText='Пока нет компаний'
      totalCount={totalCount}
      rowsPerPageText='Компаний на странице'
      refetch={() => {}} // TODO integrate refetch
    />
  );
};

export default UserCompanyList;
