import React from 'react';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { RouterProvider } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import { ToastProvider } from 'context/toastContext';
import { DialogProvider } from 'context/dialogContext';
import createAppTheme from './theme';
import router from './router';

const App = () => {
  const theme = createAppTheme();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='ru'>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <DialogProvider>
            <CssBaseline />
            <RouterProvider router={router} />
          </DialogProvider>
        </ToastProvider>
      </ThemeProvider>
    </LocalizationProvider>
  );
};

export default App;
