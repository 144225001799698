import { Chip, SxProps } from '@mui/material';

interface StatusDetail {
  title: string;
  backgroundColor: string;
  color?: string;
}

export type StatusDetails<StatusEnum extends string | number | symbol> = {
  [key in StatusEnum]: StatusDetail;
};

interface StatusProps<StatusEnum extends string | number | symbol> {
  status: StatusEnum;
  sx?: SxProps;
  statusDetails: StatusDetails<StatusEnum>;
}

const Status = <StatusEnum extends string | number | symbol>({
  status,
  statusDetails,
  sx,
}: StatusProps<StatusEnum>) => {
  const { title, ...colors } = statusDetails[status];
  return <Chip label={title} sx={{ ...colors, ...sx }} />;
};

export default Status;
