interface User {
  firstName?: string | null;
  lastName?: string | null;
  middleName?: string | null;
}

export const createUserName = (user?: User | null): string => {
  if (!user) return '—';
  const { firstName, lastName, middleName } = user;
  return firstName || lastName || middleName
    ? [firstName, lastName, middleName].filter(Boolean).join(' ')
    : '—';
};

export const getInitials = (name: string = '', join: string = ''): string => {
  return name
    .trim()
    .split(' ')
    .slice(0, 2)
    .map((word) => word.charAt(0).toUpperCase())
    .join(join);
};
