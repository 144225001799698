import { useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import qs from 'qs';
import { FilterField, FilterInputType, INPUT_DEFAULT_VALUE } from 'layout/TableLayout/types.d';
import _ from 'lodash';
import dayjs from 'dayjs';
import { UseFormReturn } from 'react-hook-form';

const useFilter = ({ form, filters }: { form: UseFormReturn; filters: FilterField[] }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const query = useMemo(() => qs.parse(searchParams.toString()), [searchParams]);

  const { setValue, reset } = form;

  useEffect(() => {
    const params = {};
    filters.forEach((filter: FilterField) => {
      const { name } = filter;
      const inputType = filter.type as FilterInputType;

      if (_.get(query, filter.name)) {
        const filterValue = _.get(query, name);
        const setParam = (newData: string | object) => _.set(params, name, newData);
        setParam(filterValue || INPUT_DEFAULT_VALUE[inputType]);
      } else _.set(params, name, INPUT_DEFAULT_VALUE[inputType]);
    });
    if (Object.keys(params).length) {
      reset(params);
    }
  }, [query]);

  const handleFiltersSubmit = (data: string | object) => {
    const params = {};
    filters.forEach((filter: FilterField) => {
      const { type, name } = filter;
      const filterValue = _.get(data, name);
      const setParam = (newData: string | object) => _.set(params, name, newData);
      if (!filterValue) return;
      switch (type) {
        case FilterInputType.DATE: {
          if (dayjs(filterValue).isValid()) setParam(filterValue.format('YYYY-MM-DD'));
          break;
        }
        default: {
          setParam(filterValue);
          break;
        }
      }
    });
    setSearchParams(qs.stringify(params));
  };

  const handleReset = () => {
    filters.forEach((filter) => {
      setValue(filter.name, undefined);
    });
    setSearchParams({}); // Очистить все query параметры
  };
  return { handleFiltersSubmit, handleReset };
};

export default useFilter;
