import TableLayout from 'layout/TableLayout';
import EMPLOYEE_LIST_COLUMNS from 'pages/Company/View/tabs/EmployeeList/columns';
import EMPLOYEE_LIST_FILTERS from 'pages/Company/View/tabs/EmployeeList/filters';

export interface Employee {
  fullName: string;
  email: string;
  position: string;
  isDirector: boolean;
  isCreator: boolean;
  id: string;
}

const EmployeeList = () => {
  const loading = false;
  const totalCount = 10;
  const data: Employee[] = [
    {
      id: '550e8400-e29b-41d4-a716-446655440000',
      fullName: 'Иванов Иван Иванович',
      email: 'ivanov@example.com',
      position: 'Генеральный директор',
      isDirector: true,
      isCreator: true,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440001',
      fullName: 'Петрова Анна Сергеевна',
      email: 'petrova@example.com',
      position: 'Финансовый директор',
      isDirector: false,
      isCreator: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440002',
      fullName: 'Сидоров Алексей Петрович',
      email: 'sidorov@example.com',
      position: 'Руководитель отдела продаж',
      isDirector: false,
      isCreator: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440003',
      fullName: 'Кузнецова Мария Ивановна',
      email: 'kuznetsova@example.com',
      position: 'Менеджер по продажам',
      isDirector: false,
      isCreator: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440004',
      fullName: 'Смирнов Дмитрий Александрович',
      email: 'smirnov@example.com',
      position: 'Технический директор',
      isDirector: false,
      isCreator: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440005',
      fullName: 'Васильева Елена Николаевна',
      email: 'vasilieva@example.com',
      position: 'HR-менеджер',
      isDirector: false,
      isCreator: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440006',
      fullName: 'Михайлов Сергей Владимирович',
      email: 'mihailov@example.com',
      position: 'Маркетолог',
      isDirector: false,
      isCreator: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440007',
      fullName: 'Федорова Ольга Дмитриевна',
      email: 'fedorova@example.com',
      position: 'Бухгалтер',
      isDirector: false,
      isCreator: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440008',
      fullName: 'Тихонов Андрей Сергеевич',
      email: 'tikhonov@example.com',
      position: 'Инженер',
      isDirector: false,
      isCreator: false,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440009',
      fullName: 'Зайцева Наталья Михайловна',
      email: 'zaitseva@example.com',
      position: 'Офис-менеджер',
      isDirector: false,
      isCreator: false,
    },
  ];

  return (
    <TableLayout<Employee>
      filters={EMPLOYEE_LIST_FILTERS}
      columns={EMPLOYEE_LIST_COLUMNS}
      data={data}
      loading={loading}
      emptyPlaceholderText='Пока нет сотрудников'
      totalCount={totalCount}
      rowsPerPageText='Сотрудников на странице'
      refetch={() => {}}
    />
  );
};

export default EmployeeList;
