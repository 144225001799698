import { TablePagination as MuiTablePagination } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'qs';
import { TablePaginationProps } from 'layout/TableLayout/types';

const LIMITS = [8, 16, 24];

export const DEFAULT_LIMIT = 8;

const TablePagination: FC<TablePaginationProps> = ({ totalCount, rowsPerPageText }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [page, setPage] = useState<number>(Number(query.page) || 0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(Number(query.limit) || DEFAULT_LIMIT);

  useEffect(() => {
    setPage(Number(query.page) || 0);
    setRowsPerPage(Number(query.limit) || DEFAULT_LIMIT);
  }, [query.page, query.limit]);

  const handleChangePage = (event: unknown, newPage: number) => {
    navigate(`?${qs.stringify({ ...query, page: newPage, limit: rowsPerPage })}`);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newLimit = parseInt(event.target.value, 10);
    setRowsPerPage(newLimit);
    setPage(0);
    navigate(`?${qs.stringify({ ...query, page: 0, limit: newLimit })}`);
  };

  return (
    <MuiTablePagination
      component='div'
      count={totalCount}
      page={page}
      onPageChange={handleChangePage}
      rowsPerPage={rowsPerPage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      labelRowsPerPage={rowsPerPageText}
      rowsPerPageOptions={LIMITS}
      labelDisplayedRows={({ from, to, count }) =>
        `${from}-${to} из ${count !== -1 ? count : `more than ${to}`}`
      }
    />
  );
};

export default TablePagination;
