import { Typography } from '@mui/material/styles/createTypography';

export default {
  fontFamily: 'Roboto',
  h40: {
    fontSize: 40,
    fontWeight: 700,
    lineHeight: '45px',
    letterSpacing: '0em',
  },
  h34: {
    fontSize: 34,
    fontWeight: 500,
    lineHeight: '36px',
  },
  h28: {
    fontSize: 28,
    fontWeight: 500,
    lineHeight: '33.6px',
    letterSpacing: '0.02em',
  },
  h24m: {
    fontSize: 24,
    fontWeight: 500,
    lineHeight: '24px',
  },
  h24rg: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '24px',
  },
  h24: {
    fontSize: 24,
    fontWeight: 400,
    lineHeight: '23px',
  },
  h20: {
    fontSize: 20,
    fontWeight: 400,
    lineHeight: '26.88px',
  },
  text: {
    fontSize: 18,
    fontWeight: 500,
    lineHeight: '24px',
  },
  body16rg: {
    fontSize: 16,
    fontWeight: 400,
    lineHeight: 1.3,
    letterSpacing: '0.015em',
  },
  body14rg: {
    fontSize: 14,
    fontWeight: 400,
    lineHeight: 1.2,
  },
} as Typography;
