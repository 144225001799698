import { generateOptions } from 'utils/format';
import { StatusDetails } from 'components/common/Status';
import palette from 'theme/palette';

export enum TrialStatus {
  Canceled = 'CANCELED',
  Completed = 'COMPLETED',
  Created = 'CREATED',
  Received = 'RECEIVED',
  Started = 'STARTED',
}

export interface Report {
  type: 'testReport' | 'extendedReport';
  url: string;
  id: string;
}

export interface Candidate {
  id: string;
  fullName: string;
  nickname: string;
}

export interface CompetencyMap {
  id: string;
  title: string;
}

export interface Testing {
  id: string;
  createdAt: string;
  competencyMap: CompetencyMap;
  candidate: Candidate;
  status: TrialStatus;
  reports?: Report[];
}

export const ASSESSMENT_STATUS_TITLE = {
  [TrialStatus.Canceled]: 'Отменено',
  [TrialStatus.Created]: 'Создано',
  [TrialStatus.Received]: 'Получено',
  [TrialStatus.Started]: 'Запущено',
  [TrialStatus.Completed]: 'Завершено',
};

export const ASSESSMENT_STATUS_OPTIONS: Array<{ id: TrialStatus; displayName: string }> =
  generateOptions(ASSESSMENT_STATUS_TITLE);

export const ASSESSMENT_STATUS_COLOR: StatusDetails<TrialStatus> = Object.entries(
  ASSESSMENT_STATUS_TITLE,
).reduce((acc, [key, title]) => {
  const status = key as TrialStatus;
  acc[status] = {
    title,
    backgroundColor: palette.status[status],
  };
  return acc;
}, {} as StatusDetails<TrialStatus>);
