import { IconButton, Menu, MenuItem } from '@mui/material';
import { MoreVert } from '@mui/icons-material';
import React, { useState } from 'react';
import { useDialog } from 'context/dialogContext';

const UserAction = ({ isBlocked, fullName }: { isBlocked: boolean; fullName: string }) => {
  const id = '550e8400-e29b-41d4-a716-446655440005';
  const { showDialog, closeDialog } = useDialog();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const actionBlockText = isBlocked ? 'Разблокировать' : 'Заблокировать';

  const onBlockUser = () => {
    // TODO integrate block user
    console.log('onBlockUser', id);
    handleCloseMenu();
    showDialog({
      title: isBlocked ? 'Разблокировать пользователя' : 'Заблокировать пользователя',
      content: isBlocked
        ? `Вы уверены, что хотите разблокировать пользователя ${fullName}?`
        : `Вы уверены, что хотите заблокировать пользователя ${fullName}?`,
      onConfirm: () => closeDialog(),
      confirmText: actionBlockText,
    });
  };

  return (
    <div>
      <IconButton onClick={handleClick}>
        <MoreVert />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleCloseMenu}>
        <MenuItem onClick={onBlockUser}>{actionBlockText}</MenuItem>
      </Menu>
    </div>
  );
};

export default UserAction;
