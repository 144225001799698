import React from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  Theme,
  ListItemIcon,
  ListItemButton,
} from '@mui/material';
import { styled, CSSObject } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import MENU_ROUTES from 'layout/MainLayout/components/menuRoutes';

const drawerWidth = 240;

const StyledDrawer = styled(Drawer)(
  (): CSSObject => ({
    width: drawerWidth,
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      width: drawerWidth,
    },
  }),
);

const ToolbarSpacer = styled('div')(({ theme }): CSSObject => (theme as Theme).mixins.toolbar);

const MenuDrawer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <StyledDrawer variant='permanent'>
      <ToolbarSpacer />
      <Box>
        <List>
          {MENU_ROUTES.map(({ title, link, icon }) => (
            <ListItem key={link} onClick={() => navigate(`/${link}`)}>
              <ListItemButton>
                <ListItemIcon>{icon}</ListItemIcon>
                <ListItemText primary={title} />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Box>
    </StyledDrawer>
  );
};

export default MenuDrawer;
