import { Stack } from '@mui/material';
import Header from 'pages/User/View/components/Header';
import TabLayout, { TabContent } from 'layout/TabLayout';
import PersonalData from './tabs/PersonalData';
import UserCompanyList from './tabs/UserCompnayList';

const TABS: TabContent[] = [
  {
    label: 'Личные данные',
    id: 'personalData',
    Component: PersonalData,
  },
  {
    label: 'Компании',
    id: 'userCompanies',
    Component: UserCompanyList,
  },
];

const UserViewPage = () => {
  return (
    <Stack spacing={2.5}>
      <Header />
      <TabLayout tabs={TABS} />
    </Stack>
  );
};

export default UserViewPage;
