export const formatNumber = (
  titles: string[],
  num: number | null | undefined,
  onlyTitle: boolean = false,
) => {
  const currentNumber = num ?? 0;
  const cases = [2, 0, 1, 1, 1, 2];
  const titleIndex =
    Math.abs(currentNumber) % 100 > 4 && Math.abs(currentNumber) % 100 < 20
      ? 2
      : cases[Math.abs(currentNumber) % 10 < 5 ? Math.abs(currentNumber) % 10 : 5];

  return (onlyTitle ? '' : `${Intl.NumberFormat().format(currentNumber)} `) + titles[titleIndex];
};

type RecordString = Record<string, string>;

export const generateOptions = <T extends RecordString>(object: T) => {
  return Object.entries(object).map(([id, displayName]) => ({
    id: id as keyof T,
    displayName,
  }));
};
