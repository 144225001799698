import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Table as MuiTable,
  TableContainer,
  Paper,
} from '@mui/material';
import qs from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';
import { Identifiable, TableProps } from 'layout/TableLayout/types';
import TablePagination from 'layout/TableLayout/components/TablePagination';

const Table = <Rows extends Identifiable>({
  columns,
  data,
  onRowClick,
  totalCount,
  rowsPerPageText,
}: TableProps<Rows>) => {
  const location = useLocation();
  const navigate = useNavigate();
  const query = qs.parse(location.search, { ignoreQueryPrefix: true });

  const handleSort = (field: string) => {
    const direction = query.sortField === field && query.sortOrder === 'asc' ? 'desc' : 'asc';
    navigate(`?${qs.stringify({ ...query, sortField: field, sortOrder: direction })}`);
  };

  return (
    <TableContainer component={Paper} elevation={0} square>
      <MuiTable>
        <TableHead>
          <TableRow>
            {columns.map((column) => (
              <TableCell key={`column-${column.name as string}`}>
                {column.sortable ? (
                  <TableSortLabel
                    active={query.sortField === column.title}
                    direction={query.sortOrder === 'asc' ? 'asc' : 'desc'}
                    onClick={() => handleSort(column.title)}>
                    {column.title}
                  </TableSortLabel>
                ) : (
                  column.title
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              sx={{
                cursor: onRowClick ? 'pointer' : 'default',
              }}
              key={`row-${row.id}`}
              onClick={() => onRowClick?.(row)}>
              {columns.map((column) => (
                <TableCell key={`cell-${column.name as string}`}>{column.render(row)}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </MuiTable>
      <TablePagination totalCount={totalCount} rowsPerPageText={rowsPerPageText} />
    </TableContainer>
  );
};

export default Table;
