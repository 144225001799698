import { IconButton, Tooltip } from '@mui/material';
import { Delete, Replay } from '@mui/icons-material';
import { useDialog } from 'context/dialogContext';
import { useToast } from 'context/toastContext';

const DeleteAction = ({
  isDeleted,
  id,
  title,
}: {
  isDeleted: boolean;
  id: string;
  title: string;
}) => {
  const { showSuccessToast } = useToast();
  const { showDialog, closeDialog } = useDialog();

  const handleRecoveryAction = () => {
    showDialog({
      title: `Вы уверены, что хотите восстановить карту компетенций ${title}`,
      confirmText: 'Восстановить',
      onConfirm: () => {
        // TODO integrate recovery competency map
        console.log('recovery', id);
        showSuccessToast('Карта компетенций восстановлена');
        closeDialog();
      },
    });
  };

  const handleDeleteAction = () => {
    showDialog({
      title: `Вы уверены, что хотите удалить карту компетенций ${title}`,
      confirmText: 'Удалить',
      onConfirm: () => {
        // TODO integrate delete competency map
        console.log('deleteAction', id);
        showSuccessToast('Карта компетенций удалена');
        closeDialog();
      },
    });
  };

  const handleClick = () => {
    if (isDeleted) handleRecoveryAction();
    else handleDeleteAction();
  };

  const tooltipText = isDeleted ? 'Восстановить карту компетенций' : 'Удалить карту компетенций';

  return (
    <Tooltip title={tooltipText}>
      <IconButton onClick={handleClick}>{isDeleted ? <Replay /> : <Delete />}</IconButton>
    </Tooltip>
  );
};

export default DeleteAction;
