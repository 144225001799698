import TableLayout from 'layout/TableLayout';
import COMPANY_TABLE from 'pages/Company/List/columns';
import { Stack } from '@mui/material';
import PageTitle from 'components/common/PageTitle';
import COMPANY_FILTERS from 'pages/Company/List/filters';
import { CompanyStatus } from 'constants/company';
import { useNavigate } from 'react-router-dom';

export interface CompanyData {
  createdAt: string;
  inn: string;
  shortName: string;
  companyCreator: string;
  status: CompanyStatus;
  id: string;
}

const CompanyListPage = () => {
  const navigate = useNavigate();
  const loading = false;
  const data = [
    {
      id: '550e8400-e29b-41d4-a716-446655440000',
      createdAt: '2023-05-15',
      inn: '1234567890',
      shortName: 'ООО Блинная Мания',
      companyCreator: 'Елена Петрова',
      status: CompanyStatus.Confirmed,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440001',
      createdAt: '2022-11-03',
      inn: '9876543210',
      shortName: 'ООО Чай и Печеньки',
      companyCreator: 'Елена Чагаева',
      status: CompanyStatus.Unconfirmed,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440002',
      createdAt: '2021-07-22',
      inn: '1122334455',
      shortName: 'ООО Кодеры из Пиццы',
      companyCreator: 'Илья Брылёв',
      status: CompanyStatus.Blocked,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440003',
      createdAt: '2020-12-12',
      inn: '5566778899',
      shortName: 'ООО Креативные Усы',
      companyCreator: 'Ксения Сопова',
      status: CompanyStatus.PartiallyConfirmed,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440004',
      createdAt: '2019-06-18',
      inn: '9988776655',
      shortName: 'ООО Бюро Гениальных Идей',
      companyCreator: 'Леонид Куценок',
      status: CompanyStatus.Confirmed,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440005',
      createdAt: '2018-09-09',
      inn: '6677889900',
      shortName: 'ООО Кофейные Котики',
      companyCreator: 'Алексей Кузьменко',
      status: CompanyStatus.Unconfirmed,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440006',
      createdAt: '2017-03-22',
      inn: '4455667788',
      shortName: 'ООО IT Сказка',
      companyCreator: 'Богдан Одарченко',
      status: CompanyStatus.PartiallyConfirmed,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440007',
      createdAt: '2015-07-15',
      inn: '2233445566',
      shortName: 'ООО Весёлый Хакер',
      companyCreator: 'Оксана Шишкина',
      status: CompanyStatus.Confirmed,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440008',
      createdAt: '2014-05-05',
      inn: '1122445566',
      shortName: 'ООО Ленивая Панда',
      companyCreator: 'Елена Петрова',
      status: CompanyStatus.Confirmed,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440009',
      createdAt: '2013-02-28',
      inn: '2233556677',
      shortName: 'ООО Чудо-Молоток',
      companyCreator: 'Илья Брылёв',
      status: CompanyStatus.Unconfirmed,
    },
  ];

  const totalCount = 10;

  return (
    <Stack spacing={2.5}>
      <PageTitle title='Список компаний' />
      <TableLayout<CompanyData>
        onRowClick={(row) => navigate(row.id)}
        columns={COMPANY_TABLE}
        filters={COMPANY_FILTERS}
        data={data}
        loading={loading}
        emptyPlaceholderText='Пока нет компаний'
        totalCount={totalCount}
        rowsPerPageText='Компаний на странице'
        refetch={() => {}}
      />
    </Stack>
  );
};

export default CompanyListPage;
