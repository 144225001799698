import React from 'react';
import {
  Dialog as MuiDialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

export interface DialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  content?: string;
  confirmText: string;
  cancelText?: string;
  isLoading?: boolean;
}

export const Dialog: React.FC<DialogProps> = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  confirmText,
  cancelText = 'Отменить',
  isLoading = false,
}) => {
  return (
    <MuiDialog open={open} onClose={onClose}>
      <DialogTitle sx={{ px: 0 }}>{title}</DialogTitle>
      {content && (
        <DialogContent>
          <DialogContentText>{content}</DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color='secondary'>
          {cancelText}
        </Button>
        <LoadingButton onClick={onConfirm} loading={isLoading} color='primary' variant='contained'>
          {confirmText}
        </LoadingButton>
      </DialogActions>
    </MuiDialog>
  );
};
