import React, { FC } from 'react';
import MuiTextField from '@mui/material/TextField';
import { Stack, TextFieldProps as MuiTextFieldProps, Typography } from '@mui/material';
import { FieldError } from 'react-hook-form';

interface TextFieldProps extends Omit<MuiTextFieldProps, 'error'> {
  error?: FieldError;
}

const TextField: FC<TextFieldProps> = (props) => {
  const {
    placeholder,
    size,
    label,
    type,
    variant,
    onChange,
    value = '',
    onBlur,
    error,
    helperText,
  } = props;
  return (
    <Stack spacing={0.5}>
      {label && (
        <Typography variant='body14rg' sx={{ color: 'base.500' }}>
          {label}
        </Typography>
      )}
      <MuiTextField
        fullWidth
        type={type}
        variant={variant}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        error={!!error}
        placeholder={placeholder}
        size={size}
        helperText={helperText}
      />
    </Stack>
  );
};

export default TextField;
