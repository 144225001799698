import { Chip, Stack } from '@mui/material';
import { CircularLoading, PageTitle } from 'components/common';
import { red } from '@mui/material/colors';
import UserAction from './UserAction';

const Header = () => {
  const loading = false;
  const data = {
    id: '550e8400-e29b-41d4-a716-446655440005',
    fullName: 'Ольга Соколова',
    isBlocked: true,
  };

  if (loading) return <CircularLoading />;
  return (
    <Stack direction={{ xs: 'column', md: 'row' }} spacing={2} justifyContent='space-between'>
      <PageTitle title={`Пользователь ${data.fullName}`} />
      <Stack direction='row'>
        {data.isBlocked && <Chip label='Заблокирован' sx={{ backgroundColor: red[200] }} />}
        <UserAction {...{ isBlocked: data.isBlocked, fullName: data.fullName }} />
      </Stack>
    </Stack>
  );
};

export default Header;
