import React, { ReactNode } from 'react';
import {
  FormControl,
  MenuItem,
  SelectChangeEvent,
  SelectProps,
  Stack,
  Typography,
} from '@mui/material';
import MuiSelect from '@mui/material/Select';
import { KeyboardArrowDown } from '@mui/icons-material';
import { FieldError } from 'react-hook-form';

/* eslint-disable react/jsx-props-no-spreading */

export type SelectCustomProps<TSelectItem, TValueName extends keyof TSelectItem> = {
  items: TSelectItem[];
  labelName?: keyof TSelectItem;
  valueName?: TValueName;
  onChange: (event: SelectChangeEvent<TSelectItem[TValueName]>, child: React.ReactNode) => void;
  onItemClick?: (item: TSelectItem) => void;
  error?: FieldError;
} & Omit<SelectProps<TSelectItem[TValueName]>, 'onChange' | 'error'>;

const Select = <TSelectItem, TValueName extends keyof TSelectItem>({
  items,
  value,
  onChange,
  sx,
  labelName = 'label' as keyof TSelectItem,
  valueName = 'value' as TValueName,
  readOnly,
  label,
  onItemClick,
  error,
  ...props
}: SelectCustomProps<TSelectItem, TValueName>) => (
  <FormControl fullWidth={props.fullWidth} component={Stack} spacing={0.5} error={!!error}>
    {label && (
      <Typography variant='body14rg' sx={{ color: 'base.500' }}>
        {label}
      </Typography>
    )}
    <MuiSelect
      fullWidth
      value={value}
      size='small'
      IconComponent={KeyboardArrowDown}
      onChange={onChange}
      sx={sx}
      MenuProps={{
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        transformOrigin: {
          vertical: 0,
          horizontal: 'center',
        },
      }}
      {...props}>
      {items.map((item) => (
        <MenuItem
          key={item[valueName] as string}
          onClick={() => onItemClick?.(item)}
          value={item[valueName] as string}>
          {item[labelName] as ReactNode}
        </MenuItem>
      ))}
    </MuiSelect>
  </FormControl>
);

export default Select;
