import cookies, { CookieAttributes } from 'js-cookie';
import dayjs from 'dayjs';

interface ICookie {
  name: string;
  value: string;
  options?: CookieAttributes;
}

export const setAuthCookies = (cookieList: ICookie[] = []) => {
  const currentHostname = window.location.hostname;
  cookieList.forEach((cookie) => {
    cookies.set(cookie.name, cookie.value, {
      expires: dayjs().add(30, 'day').valueOf(),
      domain: currentHostname === 'localhost' ? undefined : `.${currentHostname}`,
      ...cookie.options,
    });
  });
};

export const removeAuthCookies = (cookieNames: string[] = []) => {
  const currentHostname = window.location.hostname;
  cookieNames.forEach((cookieName) => {
    cookies.remove(cookieName, {
      path: '/',
      domain: currentHostname === 'localhost' ? undefined : `.${currentHostname}`,
    });
  });
};
