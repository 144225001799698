import { TableColumn } from 'layout/TableLayout/types.d';
import { UserCompany } from 'constants/user';
import dayjs from 'dayjs';
import { BOOLEAN_LABELS } from 'constants/global';

// дата добавления;
// ИНН;
// наименование компании;
// должность - должность пользователя в компании;
// создатель - является ли создателем компании:
//   да;
// нет;
// руководитель - является ли руководителем компании:
//   да;
// нет.

const USER_COMPANY_LIST_COLUMNS: TableColumn<UserCompany>[] = [
  {
    title: 'Дата добавления',
    name: 'addedAt',
    render: ({ addedAt }) => dayjs(addedAt).format('DD.MM.YYYY'),
  },
  {
    title: 'ИНН',
    name: 'inn',
    render: ({ inn }) => inn,
  },
  {
    title: 'Наименование компании',
    name: 'companyName',
    render: ({ companyName }) => companyName,
  },
  {
    title: 'Должность',
    name: 'position',
    render: ({ position }) => position,
  },
  {
    title: 'Создатель',
    name: 'isCreator',
    render: ({ isCreator }) => BOOLEAN_LABELS[`${isCreator}`],
  },
  {
    title: 'Руководитель',
    name: 'isDirector',
    render: ({ isDirector }) => BOOLEAN_LABELS[`${isDirector}`],
  },
];

export default USER_COMPANY_LIST_COLUMNS;
