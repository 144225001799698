import TableLayout from 'layout/TableLayout';
import { Testing, TrialStatus } from 'constants/assessment';
import ASSESSMENT_LIST_FILTERS from 'pages/Company/View/tabs/AssessmentList/filters';
import ASSESSMENTS_LIST_COLUMNS from './columns';

const AssessmentList = () => {
  const loading = false;
  const totalCount = 10;
  const data: Testing[] = [
    {
      id: '550e8400-e29b-41d4-a716-446655440000',
      createdAt: '2023-01-01',
      competencyMap: {
        id: '550e8400-e29b-41d4-a716-446655440001',
        title: 'Project Management',
      },
      candidate: {
        id: '550e8400-e29b-41d4-a716-446655440002',
        fullName: 'Иванов Иван Иванович',
        nickname: 'ivanov_ii',
      },
      status: TrialStatus.Completed,
      reports: [
        {
          id: '12345',
          type: 'testReport',
          url: 'http://example.com/reports/testReport1.pdf',
        },
        {
          id: '12345',
          type: 'extendedReport',
          url: 'http://example.com/reports/extendedReport1.pdf',
        },
      ],
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440003',
      createdAt: '2023-02-15',
      competencyMap: {
        id: '550e8400-e29b-41d4-a716-446655440004',
        title: 'Software Development',
      },
      candidate: {
        id: '550e8400-e29b-41d4-a716-446655440005',
        fullName: 'Петрова Анна Сергеевна',
        nickname: 'petrova_as',
      },
      status: TrialStatus.Started,
      reports: [
        {
          id: '12345',
          type: 'testReport',
          url: 'http://example.com/reports/testReport2.pdf',
        },
      ],
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440006',
      createdAt: '2023-03-10',
      competencyMap: {
        id: '550e8400-e29b-41d4-a716-446655440007',
        title: 'Data Analysis',
      },
      candidate: {
        id: '550e8400-e29b-41d4-a716-446655440008',
        fullName: 'Сидоров Алексей Петрович',
        nickname: 'sidorov_ap',
      },
      status: TrialStatus.Created,
      reports: undefined,
    },
    {
      id: '550e8400-e29b-41d4-a716-446655440009',
      createdAt: '2023-04-05',
      competencyMap: {
        id: '550e8400-e29b-41d4-a716-446655440010',
        title: 'Marketing Strategy',
      },
      candidate: {
        id: '550e8400-e29b-41d4-a716-446655440011',
        fullName: 'Кузнецова Мария Ивановна',
        nickname: 'kuznetsova_mi',
      },
      status: TrialStatus.Completed,
      reports: [
        {
          id: '12345',
          type: 'testReport',
          url: 'http://example.com/reports/testReport3.pdf',
        },
        {
          id: '12345',
          type: 'extendedReport',
          url: 'http://example.com/reports/extendedReport3.pdf',
        },
      ],
    },
  ];

  return (
    <TableLayout<Testing>
      filters={ASSESSMENT_LIST_FILTERS}
      columns={ASSESSMENTS_LIST_COLUMNS}
      data={data}
      loading={loading}
      emptyPlaceholderText='Пока нет тестирований'
      totalCount={totalCount}
      rowsPerPageText='Тестирований на странице'
      refetch={() => {}}
    />
  );
};

export default AssessmentList;
