import { GradeColor } from 'types/mui';
import palette from 'theme/palette';
import { generateOptions } from 'utils/format';
import { grey } from '@mui/material/colors';

export enum CompanyStatus {
  Created = 'Created',
  Unconfirmed = 'Unconfirmed',
  PartiallyConfirmed = 'PartiallyConfirmed',
  Confirmed = 'Confirmed',
  Blocked = 'Blocked',
}

export enum CompanyType {
  LEGAL_ENTITY = 'LEGAL_ENTITY',
  INDIVIDUAL_ENTREPRENEUR = 'INDIVIDUAL_ENTREPRENEUR',
}

export const COMPANY_STATUS_TITLE = {
  [CompanyStatus.PartiallyConfirmed]: 'Частично одобрена',
  [CompanyStatus.Confirmed]: 'Подтверждена',
  [CompanyStatus.Unconfirmed]: 'Не подтверждена',
  [CompanyStatus.Blocked]: 'Заблокирована',
  [CompanyStatus.Created]: 'Создана',
};

export const COMPANY_STATUS: {
  [key in CompanyStatus]: {
    color: GradeColor['light'];
    backgroundColor: GradeColor['contrast'];
    title: string;
  };
} = {
  [CompanyStatus.PartiallyConfirmed]: {
    color: palette.orange.light,
    backgroundColor: palette.orange.contrast,
    title: COMPANY_STATUS_TITLE[CompanyStatus.PartiallyConfirmed],
  },
  [CompanyStatus.Confirmed]: {
    color: palette.green.light,
    backgroundColor: palette.green.contrast,
    title: COMPANY_STATUS_TITLE[CompanyStatus.Confirmed],
  },
  [CompanyStatus.Unconfirmed]: {
    color: palette.red.light,
    backgroundColor: palette.red.contrast,
    title: COMPANY_STATUS_TITLE[CompanyStatus.Unconfirmed],
  },
  [CompanyStatus.Blocked]: {
    color: palette.red.light,
    backgroundColor: palette.red.contrast,
    title: COMPANY_STATUS_TITLE[CompanyStatus.Blocked],
  },
  [CompanyStatus.Created]: {
    color: grey[400],
    backgroundColor: grey[700],
    title: COMPANY_STATUS_TITLE[CompanyStatus.Created],
  },
};

export const COMPANY_STATUS_OPTIONS: Array<{ id: CompanyStatus; displayName: string }> =
  generateOptions(COMPANY_STATUS_TITLE);

export const COMPANY_TYPE = {
  [CompanyType.INDIVIDUAL_ENTREPRENEUR]: 'Индивидуальные предприниматель',
  [CompanyType.LEGAL_ENTITY]: 'Юридическое лицо',
};

export const COMPANY_TYPE_OPTIONS: Array<{ id: CompanyType; displayName: string }> =
  generateOptions(COMPANY_TYPE);
