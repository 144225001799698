import { Stack } from '@mui/material';
import TabLayout, { TabContent } from 'layout/TabLayout';
import Header from './components/Header';
import { CompanyData, EmployeeList, CompetencyMapList, AssessmentList } from './tabs';

const TABS: TabContent[] = [
  {
    label: 'Данные компании',
    id: 'companyData',
    Component: CompanyData,
  },
  {
    label: 'Сотрудники',
    id: 'employees',
    Component: EmployeeList,
  },
  {
    label: 'Карты компетенций',
    id: 'competencyMaps',
    Component: CompetencyMapList,
  },
  {
    label: 'Тестирования',
    id: 'assessments',
    Component: AssessmentList,
  },
];

const CompanyViewPage = () => {
  return (
    <Stack spacing={2.5}>
      <Header />
      <TabLayout tabs={TABS} />
    </Stack>
  );
};

export default CompanyViewPage;
