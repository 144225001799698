import { TableColumn } from 'layout/TableLayout/types';
import { Employee } from 'pages/Company/View/tabs/EmployeeList/index';
import { BOOLEAN_LABELS } from 'constants/global';

const EMPLOYEE_LIST_COLUMNS: TableColumn<Employee>[] = [
  {
    title: 'ФИО',
    name: 'fullName',
    render: (employee) => employee.fullName,
  },
  {
    title: 'Email',
    name: 'email',
    render: (employee) => employee.email,
  },
  {
    title: 'Должность',
    name: 'position',
    render: (employee) => employee.position,
  },
  {
    title: 'Руководитель',
    name: 'isDirector',
    render: ({ isDirector }) => BOOLEAN_LABELS[`${isDirector}`],
  },
  {
    title: 'Создатель компании',
    name: 'isCreator',
    render: ({ isCreator }) => BOOLEAN_LABELS[`${isCreator}`],
  },
];

export default EMPLOYEE_LIST_COLUMNS;
