import { Stack } from '@mui/material';
import Form from 'components/form/Form';
import { useForm } from 'react-hook-form';
import { useToast } from 'context/toastContext';
import { TextFieldControl } from 'components/inputs';
import { LoadingButton } from '@mui/lab';

interface PersonalDataForm {
  fullName: string;
  email: string;
}

interface User {
  id: string;
  fullName: string;
  email: string;
}

const PersonalData = () => {
  const { showSuccessToast, showErrorToast } = useToast();
  const data: User = {
    id: '550e8400-e29b-41d4-a716-446655440004',
    fullName: 'Дмитрий Кузнецов',
    email: 'dmitry.kuznetsov@example.com',
  };

  const form = useForm<PersonalDataForm>({
    values: data,
  });
  const { handleSubmit } = form;

  const onSubmit = (formData: PersonalDataForm) => {
    showSuccessToast('Данные успешно сохранены');
    console.log(formData);
  };

  const onError = () => {
    showErrorToast('Проверьте правильность заполнения формы');
  };

  return (
    <Form form={form}>
      <Stack spacing={2} sx={{ width: { xs: '100%', md: '50%' } }}>
        <TextFieldControl name='fullName' label='ФИО' rules={{ required: true }} />
        <TextFieldControl name='email' label='Email' rules={{ required: true }} />
        <LoadingButton onClick={handleSubmit(onSubmit, onError)} variant='contained'>
          Сохранить
        </LoadingButton>
      </Stack>
    </Form>
  );
};

export default PersonalData;
