import { TableColumn } from 'layout/TableLayout/types.d';
import { User } from 'constants/user';
import dayjs from 'dayjs';
import { BOOLEAN_LABELS } from 'constants/global';

const USER_LIST_COLUMNS: TableColumn<User>[] = [
  {
    title: 'Дата создания',
    name: 'createdAt',
    render: ({ createdAt }) => dayjs(createdAt).format('DD.MM.YYYY'),
  },
  {
    title: 'ФИО',
    name: 'fullName',
    render: ({ fullName }) => fullName,
  },
  {
    title: 'Email',
    name: 'email',
    render: ({ email }) => email,
  },
  {
    title: 'Заблокирован',
    name: 'isBlocked',
    render: ({ isBlocked }) => BOOLEAN_LABELS[`${isBlocked}`],
  },
];

export default USER_LIST_COLUMNS;
